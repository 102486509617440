import { Subject } from 'rxjs';
import { AliceAnimationsComponent } from './alice-animations.component';
import { AnimationOptions } from 'ngx-lottie';

export type AliceAnimationsState = 'sad' | 'idle' | 'happy' | 'lip_sync_1' | 'lip_sync_2' | 'think';

const STATES: { [key: string]: string } = {
  sad: 'assets/lotties/alice/sad.json',
  idle: 'assets/lotties/alice/idle.json',
  happy: 'assets/lotties/alice/happy.json',
  think: 'assets/lotties/alice/think.json',
  lip_sync_1: 'assets/lotties/alice/lip_sync_1.json',
  lip_sync_2: 'assets/lotties/alice/lip_sync_2.json',
};

export const defaultAliceAnimationOptions: AnimationOptions = {
  path: STATES['idle'],
  loop: true,
};

export class AliceAnimations {
  component: AliceAnimationsComponent;

  state: AliceAnimationsState = 'idle';
  defaultOptions: AnimationOptions = {
    path: STATES[this.state],
    loop: true,
  };

  animationQueue: AnimationOptions[] = [];

  onStateChange: Subject<AliceAnimationsState> = new Subject();
  onOptionChanges: Subject<AnimationOptions> = new Subject();

  private readonly TALK_SIZE = 10;

  constructor(aliceComponent: AliceAnimationsComponent) {
    this.component = aliceComponent;
  }

  public setDefault(state: AliceAnimationsState) {
    this.defaultOptions = {
      path: STATES[state],
      loop: true,
    };
  }

  private setNextInQueue() {
    console.log('Queue: ', this.getQueue());
    const next = this.getQueue().shift() || this.defaultOptions;
    this.onOptionChanges.next(next);
  }

  private setState(state: AliceAnimationsState) {
    this.state = state;
    this.onStateChange.next(state);
  }

  private getQueue() {
    return this.animationQueue;
  }

  public play(data: { state: AliceAnimationsState; loop?: boolean; append?: boolean }) {
    const options = {
      path: STATES[data.state],
      loop: !!data.loop,
    };

    console.log('Playing: ', data);

    if (data.append) {
      this.animationQueue.push(options);
    } else {
      // this.animationQueue = [];
      this.clearQueue();
      this.onOptionChanges.next(options);
    }
  }

  public clearQueue() {
    console.log('Clearing queue');
    this.animationQueue = [];
  }

  public talk(append: boolean = false) {
    this.play({ state: 'lip_sync_1', append });

    new Array(this.TALK_SIZE).fill(0).forEach(() => {
      this.play({ state: Math.random() >= 0.5 ? 'lip_sync_1' : 'lip_sync_2', append: true });
    });

    console.log('Queue: ', this.getQueue());
  }

  public onCompleted() {
    console.log('Completed');
    this.setNextInQueue();
  }
}
