import { Component, DestroyRef, inject, input, NgZone, OnInit, output } from '@angular/core';
import { AnimationOptions, LottieComponent, provideCacheableAnimationLoader, provideLottieOptions } from 'ngx-lottie';
import { AliceAnimations, defaultAliceAnimationOptions } from './alice-animations.utils';
import player from 'lottie-web';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export type AliceTypes = 'animation' | 'static' | 'avatar';

@Component({
  selector: 'app-alice-animations',
  standalone: true,
  imports: [LottieComponent],
  providers: [
    provideLottieOptions({
      player: () => player,
    }),
    provideCacheableAnimationLoader(),
  ],
  templateUrl: './alice-animations.component.html',
  styleUrl: './alice-animations.component.scss',
})
export class AliceAnimationsComponent implements OnInit {
  private readonly ngZone = inject(NgZone);
  private readonly destroyRef = inject(DestroyRef);
  public type = input<AliceTypes>('animation');

  public onReady = output<AliceAnimations>();

  public handler?: AliceAnimations;

  options: AnimationOptions = defaultAliceAnimationOptions;
  defaultOptions: AnimationOptions = defaultAliceAnimationOptions;

  ngOnInit(): void {
    // This should be executed after constructor
    if (this.type() !== 'animation') return;

    this.handler = new AliceAnimations(this);

    this.ngZone.runOutsideAngular(() => {
      this.handler?.onOptionChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(options => {
        this.options = options;
      });
    });

    this.onReady.emit(this.handler);
  }

  complete(): void {
    if (!this.handler) return;

    this.handler.onCompleted();
  }
}
